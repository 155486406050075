import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import PromotionSlide from "components/PromotionSlide"
import AuthService from '../services/authService';

const Promotions  = () => {
  const [userLogged, setUserLogged] = useState({})
  useEffect(() => {
    const checkUser = async () => {
      const userData = await AuthService.getUser();
      setUserLogged(userData);
      
    }
    checkUser();
  },[])

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>Estas promociones son exclusivas de Cliente: {userLogged.category?.name}</CardHeader>
              <CardBody style={{ textAlign: 'center'}}>
                <PromotionSlide />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Promotions;
