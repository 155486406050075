import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import SalesList from "components/Sales";

class Sales extends React.Component {
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Historial de Compras</CardTitle>
                </CardHeader>
                <CardBody>
                  <SalesList />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Sales;