import React from "react";
import { Grid } from "semantic-ui-react";

export default function DetailResponsive({ details, formatter }) {
  return details.map((item, index) => (
    <div
      key={index}
      style={{ marginTop: 10, boxShadow: "0px 2px 8px #CFD5DA", padding: 7 }}
    >
      <Grid columns={16}>
        <Grid.Column computer={16}>
          <strong>Descripción:</strong> {item.descripcion}
        </Grid.Column>
        <Grid.Column computer={5}>
          <strong>Descuento:</strong>
          {formatter.format(item.importe_descuento)}
        </Grid.Column>
        <Grid.Column computer={5}>
          <strong>Importe:</strong> {formatter.format(item.importe)}
        </Grid.Column>
      </Grid>
    </div>
  ));
}
