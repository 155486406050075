import apiResource from "../api/apiResource";
const serviceUrl = "uploadphoto";
const UploadService = {
  uploadPhoto: async ({ file, name }) => {
    let data = new FormData();
    data.append("file", file);
    data.append("name", name);
    const response = await apiResource.fetch("POST", serviceUrl, data);
    return { success: true, data: response.data };
  },
};

export default UploadService;
