import React, { useEffect, useState, useRef } from "react";
import { Card, CardHeader, CardBody, FormGroup, Row, Col } from "reactstrap";
import { Button, Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import ClientService from "../../services/clientService";
import CityService from "../../services/cityService";
import NotificationAlert from "react-notification-alert";

import { DateInput } from "semantic-ui-calendar-react";
import AuthService from "../../services/authService";
import UploadService from "../../services/uploadService";
import PhotoProfile from "./PhotoProfile";
import ChangePassword from "./Password";
export default function Profile({ user }) {
  const [sending, setSending] = useState(false);
  const [cities, setCities] = useState([]);
  const [birthdate, setBirthdate] = useState("");
  const [photo, setPhoto] = useState();
  const [temporaryPhoto, setTemporaryPhoto] = useState();

  const convertFormatDate = (date) => {
    if (!date) {
      return "";
    }
    const birthdateArray = date.substring(0, 10).split("-");
    return `${birthdateArray[2]}-${birthdateArray[1]}-${birthdateArray[0]}`;
  };

  const {
    register,
    errors,
    handleSubmit,
    setValue,
    triggerValidation,
  } = useForm({
    defaultValues: {
      name: user.name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      birthdate: convertFormatDate(user.birthdate),
      city_id: user.city_id,
      photo: user.photo,
    },
  });
  const notificationAlertRef = useRef(null);

  useEffect(() => {
    setBirthdate(convertFormatDate(user.birthdate));
  }, [user]);

  useEffect(() => {
    const getCities = async () => {
      const responseCities = await CityService.list();
      if (responseCities.success) {
        const listCities = responseCities.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setCities(listCities);
      }
    };
    getCities();
  }, []);

  useEffect(() => {
    register({ name: "name" }, { required: true });
    register({ name: "last_name" }, { required: true });
    register({ name: "phone" }, { required: true });
    register(
      { name: "email" },
      { required: true },
      {
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "invalid email address",
        },
      }
    );
    register({ name: "birthdate" });
    register({ name: "city_id" }, { required: true });
    register({ name: "photo" });
  }, [register]);

  const onSubmit = async (data) => {
    setSending(true);
    if (photo) {
      const photoResponse = await UploadService.uploadPhoto({
        file: photo,
        name: "file",
      });
      data["photo"] = photoResponse.data;
    }
    const response = await ClientService.update(data);
    if (response.success) {
      await AuthService.updateUser(response);
      notify(true);
      setSending(false);
    } else {
      notify(false, response.data);
      setSending(false);
    }
  };

  const notify = (type, msg = "") => {
    var options = {};
    const message = type ? `Tus datos han sido actualizados con éxito.` : msg;
    options = {
      place: "tc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type ? "success" : "danger",
      icon: type
        ? "now-ui-icons ui-1_check"
        : "now-ui-icons ui-1_simple-remove",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const temporaryPhoto = URL.createObjectURL(event.target.files[0]);
    setTemporaryPhoto(temporaryPhoto);
    setPhoto(file);
  };

  return (
    <div className="content">
      <NotificationAlert ref={notificationAlertRef} />
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Actualizar Perfil</h5>
            </CardHeader>
            <CardBody>
              <Form size="large" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col className="pr-1" md="6">
                    <FormGroup>
                      <label>Nombre(s)</label>
                      <Form.Input
                        defaultValue={user.name}
                        placeholder=""
                        type="text"
                        name="name"
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={errors.name ? true : false}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="6">
                    <FormGroup>
                      <label>Apellido(s)</label>
                      <Form.Input
                        defaultValue={user.last_name}
                        placeholder=""
                        type="text"
                        name="last_name"
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={errors.last_name ? true : false}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="5">
                    <FormGroup>
                      <label>Correo electrónico</label>
                      <Form.Input
                        defaultValue={user.email}
                        placeholder=""
                        type="text"
                        name="email"
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={errors.email ? true : false}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="3">
                    <FormGroup>
                      <label>Teléfono</label>
                      <Form.Input
                        defaultValue={user.phone}
                        placeholder=""
                        type="text"
                        name="phone"
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={errors.phone ? true : false}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="4">
                    <FormGroup>
                      <label>Fecha de Nacimiento</label>
                      <DateInput
                        closable
                        name="birthdate"
                        value={birthdate}
                        iconPosition="left"
                        onChange={async (e, { name, value }) => {
                          setBirthdate(value);
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Ciudad</label>
                      <Form.Dropdown
                        placeholder=""
                        fluid
                        selection
                        name="city_id"
                        defaultValue={user.city_id}
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={errors.city_id ? true : false}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Sucursal</label>
                      <Form.Input
                        defaultValue={user.branch_office.name}
                        placeholder=""
                        type="text"
                        name="branchoffice"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>RFC</label>
                      <Form.Input
                        defaultValue={user.rfc}
                        placeholder=""
                        type="text"
                        name="rfc"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="8">
                    <label>Fotografía</label>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileUpload}
                    />
                  </Col>
                </Row>

                <hr />
                <Button
                  disabled={sending}
                  type="submit"
                  className="sign-button"
                >
                  Guardar Cambios
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="card-user">
            <div className="image"></div>
            <CardBody>
              <div className="author">
                <PhotoProfile
                  avatar={temporaryPhoto ? temporaryPhoto : user.photo}
                />
                <h5 className="title">{`${user.name} ${user.last_name}`}</h5>
                <h5 className="subtitle">Ranking: {user.ranking}</h5>
                <h5 className="subtitle">Categoría: {user.category?.name}</h5>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <ChangePassword />
        </Col>
      </Row>
    </div>
  );
}
