import React from "react";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

class GameRules extends React.Component {
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={12}>
              <Card className="card-green">
                <CardHeader>
                  <h5 className="title">Reglas del Juego</h5>
                </CardHeader>
                <CardBody>  
                  <div>
                    <p>
                      <strong>CLUB INSTALA</strong>
                    </p>
                    <p>
                      <strong>¿QUE ES?</strong>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        CLUB INSTALA, es un programa de lealtad creado por la empresa ECOCLIMAS, y
                        en beneficio de sus clientes instaladores de equipos de aires, compradores
                        de refacciones y uso de los servicios de mantenimiento de los
                        climas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        CLUB INSTALA, es para aquella persona que se dedica al negocio de instalar
                        climas y que desee los beneficios económicos, educativos, y sociales que
                        genera el programa mediante sus diversas compensaciones en apoyo progreso
                        del instalador.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        El objetivo del programa es fortalecer la relación con sus clientes
                        proporcionándoles beneficios adicionales desde mejores precios en la
                        compra de equipos por monto comprado, promociones especiales, premios y
                        regalos en eventos especiales, de esta manera visualice a ECOCLIMAS como
                        siempre la mejor opción para compras sus equipos, refacciones y servicios
                        de climas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        El club INSTALA es un programa que está diseñado para nuestros
                        instaladores crezcan como profesionales.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Bienvenido a todo instalador que quiera hacer crecer su
                        negocio¡¡¡&nbsp;&nbsp;
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>ALCANCE&nbsp;</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        El programa tiene validez en todas las sucursales de Ecoclimas a nivel
                        Nacional , y se activa al registrarte en la página web www.clubinstala.com ó a través de su app móvil que puedes bajar en google play o Apple
                        Store.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>¿PARA QUIEN ES EL PROGRAMA?</strong>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Para clientes de la empresa Ecoclimas , que se hayan registrado como
                        instaladores y que compren equipos, refacciones y/o usen los servicios de
                        mantenimiento de la empresa.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        El cliente del CLUB INSTALA al registrarse, tendrá acceso al programa de
                        lealtad, donde podrá visualizar: sus pesos ganados,&nbsp; el segmento de
                        cliente a la cual pertenece según sus volúmenes de compra, promociones
                        personalizadas y demás información valiosa que se enfoque a ayudarlos a
                        crecer como instalador profesional.&nbsp;
                      </span>
                    </p>
                    <p>
                      <br />
                      <br />
                      <br />
                    </p>
                    <p>
                      <strong>¿EN QUE CONSISTE SUS BENEFICIOS?</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        El CLUB INSTALA tiene tres sistemas de beneficios&nbsp; para sus clientes
                        leales:
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        1.- PRECIOS PREFERENTES BASADO EN EL MONTO DE COMPRA DE LOS EQUIPOS
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        2.- PROMOCIONES ESPECIALES POR SEGMENTO DE CLIENTES DE MANERA MENSUAL
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        3.- MONEDERO ELECTRÓNICO QUE&nbsp; AYUDARA A ACUMULAR PESOS GANADOS&nbsp;
                        POR COMPRAS DE REFACCIONES, HERRAMIENTAS Y SERVICIOS.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        4.- INFORMACION SOBRE EL HISTORIAL DE&nbsp; TUS COMPRAS REALIZADAS EN
                        ECOCLIMAS
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        5.- NOTIFICACIONES SOBRE EVENTOS QUE LA EMPRESA REALIZARA EN EL AÑO COMO:
                        CURSOS, TALLERES, INAUGURACIONES, EVENTOS ESPECIALES, ETC.
                      </span>
                    </p>
                    <p>
                      <br />
                      <br />
                    </p>
                    <p>
                      <strong>TIPOS DE SEGMENTOS DE CLIENTES&nbsp;</strong>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Existen tres tipos de segmentos de clientes instaladores:
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>a.- CLIENTES PLATA</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>B.- CLIENTES ORO</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>C.- CLIENTES DIAMANTE</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        La segmentación está basada en el ranking de compra que realice de forma
                        acumulada, donde el 20% que más compre estará en el segmento DIAMANTE, el
                        30% posterior que más compre estará en el segmento de ORO y el 50%
                        posterior de clientes estarán en el segmento de PLATA. Cada cliente
                        instalador puede ir cambiando de segmento dependiendo de sus volumen de
                        compra con respecto a los demás instaladores y se actualiza el ranking de
                        forma semanal.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        El programa de lealtad le indicará su ranking de cliente basado en el
                        volumen de compra actual y el segmento a la cual pertenece en función de
                        ese ranking. De igual manera, se le informará sobre los niveles que le
                        faltaría para subir al siguiente nivel de segmento.&nbsp;
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        PROMOCIONES PERSONALIZADAS POR SEGMENTO
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Cada cliente instalador desde el momento de su registro en el programa de
                        lealtad de CLUB INSTALA obtiene Beneficios de forma automática, eso
                        incluye las promociones personalizadas por segmento , en la cual a través
                        de la APP móvil o la página Web , podrá visualizar las promociones
                        diversas que se adjudicarán de manera mensual para sus clientes. Dichas
                        promociones van a depender del tipo de segmento a la cual pertenece el
                        cliente instalador y cabe recalcar que esas promociones personalizadas no
                        se asignarán al público en general, ya que serán propiamente de los que se
                        hayan registrado y sean instaladores de climas.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Las promociones se colocarán en el apartado de PROMOCIONES y serán
                        asignadas de manera mensual por parte de la empresa. Para ser factibles
                        sus promociones no es necesario presentar ninguna publicidad, ya que la
                        promoción está activa en el Punto de venta de cada sucursal de Ecoclimas a
                        nivel Nacional.
                      </span>
                    </p>
                    <p>
                      <br />
                      <br />
                    </p>
                    <p>
                      <strong>
                        MONEDERO ELECTRONICO PARA ACUMULAR PESOS GANADOS POR COMPRAS REALIZADAS
                      </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Como miembro del CLUB INSTALA tiene la opción de obtener PESOS GANADOS en
                        forma de Monedero Electrónico, que podrá usar en sus siguientes
                        compras.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        CLUB INSTALA&nbsp; premia a sus clientes instaladores obteniendo PESOS
                        GANADOS en base al % de compra que haya realizado en las sucursales y esos
                        valores se verá reflejados en sus APP y Página Web para mejor información,
                        de esa manera se puede enterar con mayor eficiencia&nbsp; facilidad de sus
                        PESOS GANADOS acumulados y que podría utilizarlos en sus próximas compras
                        en las sucursales de Ecoclimas.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        LOS PESOS GANADOS en el monedero electrónico tienen una vigencia máxima de
                        2 meses. Después de ese tiempo de vigencia y si no se han usado los PESOS
                        GANADOS, el cliente no podrá usarlos posteriormente y se reducirá ese
                        valor de sus PESOS GANADOS.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Con la acumulación de PESOS GANADOS podrá realizar sus compras de
                        refacciones y equipos&nbsp;
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Se acumulan puntos en diferentes líneas:
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>1.- refacciones</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>2.- servicios</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>3.- herramientas</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>4.- souvenirs&nbsp;</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        INFORMACION DE ANUNCIOS IMPORTANTES:
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        De igual forma , al pertenecer al CLUB INSTALA, todos sus socios podrá
                        visualizar en su APP y página web, las diversas actividades como :
                        capacitaciones, talleres, eventos, inauguraciones y demás actividades,
                        para que este informado y aproveche al máximo todos los beneficios que
                        ECOCLIMAS quiere proporcionarle.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>INFORMACION IMPORTANTE:</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        De igual forma , el pertenecer al CLUB INSTALA tiene la ventaja de obtener
                        la información de sus compras realizadas anteriormente y también su
                        información histórica sobre sus pesos ganados acumulados y su historial de
                        ranking y tipo de segmento de cliente .
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>ATENCION A CLIENTES:</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Para cualquier duda o aclaración , CLUB INSTALA pone a su disposición un
                        CONTACTO dentro de su APP o página Web para que lo podamos atender frente
                        a cualquier problema relacionado al PROGRAMA DE LEALTAD.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default GameRules;
