import axios from 'axios';
import apiRoot from "../api/apiRoot";
import Auth from "../services/authService";

export default class ApiResource{
  static async fetch(method, url, body = null) {
    let config = {
      headers: { 
        "Content-Type": "application/json" 
      }
    }
    const accessToken = await Auth.getToken();
    if(accessToken){
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    try {
      let response;
      if(method === 'get' || method === 'GET'){
        response = await axios.get(`${apiRoot}/${url}`, config)
      }else{
        response = await axios.post(`${apiRoot}/${url}`, body, config)
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}