import React, { useEffect, useState, useRef } from "react";
import { Button, Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import contactService from "../../services/contactService";
import NotificationAlert from "react-notification-alert";
import fondoizq from "../../assets/img/contact/fondoizq.png";
import monito from "../../assets/img/contact/monito.png";
import textoecoclimas from "../../assets/img/contact/textoecoclimas.png";
import descripcion from "../../assets/img/contact/descripcion.png";
import logoazul from "../../assets/img/contact/logoazul.png";

export default function ContactForm() {
  const [sending, setSending] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    triggerValidation,
  } = useForm();
  const notificationAlertRef = useRef(null);

  useEffect(() => {
    register({ name: "message" }, { required: true });
  }, [register]);

  const onSubmit = async (data, e) => {
    setSending(true);
    await contactService.send(data);
    setSending(false);
    notifySuccess();
  };

  const notifySuccess = () => {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            Se ha enviado un correo electrónico a nuestros asesores, se pondrán
            en contacto contigo a la brevedad.
          </div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_check",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <div>
      <NotificationAlert ref={notificationAlertRef} />
      <div className="formcontact-container">
        <div className="contact-wrapper">
          <div className="contact-col1">
            <img alt="" src={fondoizq} />
            <img alt="" src={monito} className="contact-monito" />
          </div>
          <div className="contact-col2">
            <img alt="" src={descripcion} className="img-middle" />
          </div>
          <div className="contact-col3">
            <img alt="" src={logoazul} className="logo" />
            <h1>Contáctanos</h1>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.TextArea
                placeholder="Ingresa aquí tu mensaje"
                style={{ minHeight: 100, maxWidth: 400 }}
                name="message"
                onChange={async (e, { name, value }) => {
                  setValue(name, value);
                  await triggerValidation(name);
                }}
                error={errors.message ? true : false}
              />
              <Button disabled={sending} type="submit" className="sign-button">
                {!sending ? "Enviar" : "Enviando"}
              </Button>
            </Form>
            <img alt="" src={textoecoclimas} className="logo-bottom" />
          </div>
        </div>
      </div>
    </div>
  );
}
