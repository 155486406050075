import apiResource from "../api/apiResource";

const serviceUrl = 'locations';

const LocationService = {
    list: async () => {
      const response = await apiResource.fetch('GET', serviceUrl);
      return {success: true, data: response.data};
    },
}

export default LocationService;