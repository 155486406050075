import apiResource from "../api/apiResource";
const serviceUrl = "";
const EcopesosService = {
  getHistoryWallet: async () => {
    const response = await apiResource.fetch(
      "GET",
      `${serviceUrl}history_ecopesos`
    );
    return { success: true, data: response.data };
  },
};

export default EcopesosService;
