import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import LocationService from "../../services/locationService";

export default function MapWrapper({ latitude, longitude }) {
  console.log("latitude", latitude);
  console.log("longitude", longitude);
  const [myLocation] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [locations, setLocations] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState({
    lat: 24.8015616,
    lng: -107.4007367,
  });
  useEffect(() => {
    const getLocations = async () => {
      const resp = await LocationService.list();
      if (resp.success) {
        setLocations(resp.data);
      }
    };
    getLocations();
  }, []);

  useEffect(() => {
    const getDefaultLocations = async () => {
      let minDistanceLocation = { lat: 0, lng: 0 };
      await locations.map((item, index) => {
        if (index === 0) {
          const distanceLocation = distance(
            parseFloat(item.latitude),
            parseFloat(item.longitude),
            myLocation.lat,
            myLocation.lng
          );
          minDistanceLocation = {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
            distance: distanceLocation,
          };
        } else {
          const distanceLocation = distance(
            parseFloat(item.latitude),
            parseFloat(item.longitude),
            myLocation.lat,
            myLocation.lng
          );
          if (distanceLocation < minDistanceLocation.distance) {
            minDistanceLocation = {
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
              distance: distanceLocation,
            };
          }
        }
      });
      await setDefaultLocation({
        lat: minDistanceLocation.lat,
        lng: minDistanceLocation.lng,
      });
    };
    getDefaultLocations();
  }, [locations, myLocation]);

  function distance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === "K") {
        dist = dist * 1.609344;
      }
      if (unit === "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  const MapWrapper = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBSUzFtbjPqDzvfCdVoUxUeZEAe4IMf6pI"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        defaultZoom={15}
        defaultCenter={defaultLocation}
        defaultOptions={{
          scrollwheel: false,
          styles: [
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
            },
            {
              featureType: "landscape",
              elementType: "geometry",
              stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.fill",
              stylers: [{ color: "#ffffff" }, { lightness: 17 }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                { color: "#ffffff" },
                { lightness: 29 },
                { weight: 0.2 },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [{ color: "#ffffff" }, { lightness: 18 }],
            },
            {
              featureType: "road.local",
              elementType: "geometry",
              stylers: [{ color: "#ffffff" }, { lightness: 16 }],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#dedede" }, { lightness: 21 }],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                { visibility: "on" },
                { color: "#ffffff" },
                { lightness: 16 },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                { saturation: 36 },
                { color: "#333333" },
                { lightness: 40 },
              ],
            },
            { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
            },
            {
              featureType: "administrative",
              elementType: "geometry.fill",
              stylers: [{ color: "#fefefe" }, { lightness: 20 }],
            },
            {
              featureType: "administrative",
              elementType: "geometry.stroke",
              stylers: [
                { color: "#fefefe" },
                { lightness: 17 },
                { weight: 1.2 },
              ],
            },
          ],
        }}
      >
        {locations.map((item, index) => {
          return (
            <Marker
              key={index}
              position={{
                lat: parseFloat(item.latitude),
                lng: parseFloat(item.longitude),
              }}
              icon="https://administrador.clubinstala.com/storage/pinmarker-ecoclimas.png"
            />
          );
        })}
      </GoogleMap>
    ))
  );

  return (
    <MapWrapper
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBSUzFtbjPqDzvfCdVoUxUeZEAe4IMf6pI"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
}
