import React from "react";
import { Form } from "semantic-ui-react";

export default function EmailValidate({ setValue, errors }) {
  return (
    <div>
      <div className="step-description-password-recovery">
        Paso 1 de 3, ingresa tu correo electrónico
      </div>
      <Form.Input
        fluid
        icon="mail"
        iconPosition="left"
        placeholder="Ingresa Correo electrónico"
        name="email"
        onChange={async (e, { name, value }) => {
          setValue(name, value);
        }}
        error={errors ? true : false}
      />
      {errors && <div className="error-text-password-recovery">{errors}</div>}
    </div>
  );
}
