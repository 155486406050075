import React, { useState, useEffect } from "react";
import PlataImg from "../../assets/img/cat_plata.jpg";
import OroImg from "../../assets/img/cat_oro.jpg";
import DiamanteImg from "../../assets/img/cat_diamante.jpg";

export default function DashboardCategory({ categoryId, ranking }) {
  const [currentRanking, setCurrentRanking] = useState(0);
  useEffect(() => {
    const rankingString = !ranking ? "" : ranking + "";
    const padRanking = rankingString.padStart(3, 0);
    setCurrentRanking(padRanking);
  }, [setCurrentRanking, ranking]);

  let imgCategory = PlataImg;
  switch (categoryId) {
    case 1:
      imgCategory = PlataImg;
      break;
    case 2:
      imgCategory = OroImg;
      break;
    case 3:
      imgCategory = DiamanteImg;
      break;
    default:
      imgCategory = PlataImg;
  }
  return (
    <div>
      <div className="category-card-img">
        <img alt="" src={imgCategory} />
        <div className={`values-container values-container_${categoryId}`}>
          <div className="current-rankins">{currentRanking}</div>
        </div>
      </div>
    </div>
  );
}
