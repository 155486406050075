import React from "react";
import defaultAvatar from "../../assets/img/default-avatar.png";

export default function PhotoProfile({ avatar }) {
  return (
    <img
      alt="..."
      className="avatar border-gray"
      src={!avatar ? defaultAvatar : avatar}
    />
  );
}
