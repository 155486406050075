import React, { useState, useEffect } from "react";
import PlataImg from "../../assets/img/felicidades_plata.jpg";
import OroImg from "../../assets/img/felicidades_oro.jpg";
import DiamanteImg from "../../assets/img/felicidades_diamante.jpg";
import ClientService from "../../services/clientService";

export default function DashboardRulesCard({ categoryId, ranking }) {
  const [rankingNextLevel, setRankingNextLevel] = useState("000");
  const [currentRanking, setCurrentRanking] = useState(0);
  useEffect(() => {
    const getNextLevel = async () => {
      const response = await ClientService.getNextLevel();
      if (response.success) {
        setRankingNextLevel(response.data);
        const rankingString = !ranking ? "" : ranking + "";
        const padRanking = rankingString.padStart(3, 0);
        setCurrentRanking(padRanking);
      }
    };
    getNextLevel();
  }, [setRankingNextLevel, setCurrentRanking, ranking]);

  let imgCategory = PlataImg;
  switch (categoryId) {
    case 1:
      imgCategory = PlataImg;
      break;
    case 2:
      imgCategory = OroImg;
      break;
    case 3:
      imgCategory = DiamanteImg;
      break;
    default:
      imgCategory = PlataImg;
  }
  return (
    <div>
      <div className="rules-card-img">
        <img alt="" src={imgCategory} />
        {categoryId < 3 && (
          <div>
            <div className="ranking current-ranking">{currentRanking}</div>
            <div className="ranking next-ranking">{rankingNextLevel}</div>
          </div>
        )}
      </div>
    </div>
  );
}
