import React from "react";
import { Line } from "react-chartjs-2";
import descripcion from "../assets/img/mensajegraficas_julio.png";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import RankingHistoryChart from "../components/RankingHistoryChart";
import HistoryPointsChart from "../components/HistoryPointsChart";
import { dashboardPanelChart } from "variables/charts.jsx";

class RankingHistory extends React.Component {
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    height: 335,
                  }}
                >
                  <CardHeader>Historial de Ranking por Mes</CardHeader>
                  <RankingHistoryChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    height: 335,
                  }}
                >
                  <CardHeader>Historial de Puntos Ganados por Mes</CardHeader>
                  <HistoryPointsChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default RankingHistory;
