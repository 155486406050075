import React, { Fragment } from "react";
import { Table } from "semantic-ui-react";

export default function Detail({ details, formatter }) {
  return (
    <Fragment>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Código</Table.HeaderCell>
            <Table.HeaderCell width={6}>Descripción</Table.HeaderCell>
            <Table.HeaderCell width={2}>Descuento</Table.HeaderCell>
            <Table.HeaderCell width={2}>Importe</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {details.length > 0 &&
            details.map((item, index) => (
              <Table.Row key={index}>
                <Table.Cell>{item.codigo}</Table.Cell>
                <Table.Cell>{item.descripcion}</Table.Cell>
                <Table.Cell className="text-right">
                  {formatter.format(item.importe_descuento)}
                </Table.Cell>
                <Table.Cell className="text-right">
                  {formatter.format(item.importe)}
                </Table.Cell>
              </Table.Row>
            ))}
          {details.length === 0 && (
            <Table.Row>
              <Table.Cell colspan="7">No se encontraron productos</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Fragment>
  );
}
