import React, { useState, useEffect } from "react";
import Wallet from "../components/Wallet";
import DashboardCategory from "../components/DashboardCategory";
import DashboardRulesCard from "../components/DashboardRulesCard";
import AuthService from "../services/authService";
import Welcome from "../assets/img/welcome.jpg";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const Dashboard = () => {
  const [userLogged, setUserLogged] = useState({});
  useEffect(() => {
    const checkUser = async () => {
      const userData = await AuthService.getUser();
      setUserLogged(userData);
    };
    checkUser();
  }, []);

  return (
    <>
      <PanelHeader
        size="sm"
        ecopesos={userLogged.points < 0 ? 0 : userLogged.points}
      />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody className="text-center">
                <img alt="" src={Welcome} />
                <div className="welcomebanner-name">
                  Bienvenido, {userLogged.name}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Monedero electrónico</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Wallet
                    points={userLogged.points < 0 ? 0 : userLogged.points}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Categoría</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <DashboardCategory
                    categoryId={userLogged.category_id}
                    ranking={userLogged.ranking}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">¿Cómo subir de categoría?</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <DashboardRulesCard
                    categoryId={userLogged.category_id}
                    ranking={userLogged.ranking}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
