import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { geolocated } from "react-geolocated";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import MapLocations from "components/MapWrapper";

class FullScreenMap extends React.Component {
  render() {
    return !this.props.isGeolocationAvailable ? (
      <div></div>
    ) : !this.props.isGeolocationEnabled ? (
      <div></div>
    ) : this.props.coords ? (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div
                    id="map"
                    className="map"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    {this.props.coords && (
                      <MapLocations
                        latitude={this.props.coords.latitude}
                        longitude={this.props.coords.longitude}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    ) : (
      <div></div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(FullScreenMap);
