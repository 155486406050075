/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.2.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.jsx";
import { Login } from "./views/Login";
import { SignUp } from "./views/SignUp";
import Logout from "./views/Logout";
import { PasswordRecovery } from "./views/PasswordRecovery";
const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/registro" render={(props) => <SignUp {...props} />} />
      <Route
        path="/recuperarpassword"
        render={(props) => <PasswordRecovery {...props} />}
      />
      <Route path="/cerrarsesion" render={(props) => <Logout {...props} />} />
      <Route path="/inicio" render={(props) => <AdminLayout {...props} />} />
      <Route path="/tiendas" render={(props) => <AdminLayout {...props} />} />
      <Route
        path="/historial-compras"
        render={(props) => <AdminLayout {...props} />}
      />
      <Route
        path="/historial-ecopesos"
        render={(props) => <AdminLayout {...props} />}
      />
      <Route
        path="/historial-ranking"
        render={(props) => <AdminLayout {...props} />}
      />
      <Route
        path="/promociones"
        render={(props) => <AdminLayout {...props} />}
      />
      <Route path="/anuncios" render={(props) => <AdminLayout {...props} />} />
      <Route
        path="/reglas-del-juego"
        render={(props) => <AdminLayout {...props} />}
      />
      <Route
        path="/aviso-de-privacidad"
        render={(props) => <AdminLayout {...props} />}
      />
      <Route path="/contacto" render={(props) => <AdminLayout {...props} />} />
      <Route path="/perfil" render={(props) => <AdminLayout {...props} />} />
      <Redirect to="/login" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
