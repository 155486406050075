import React from "react";
import { Grid } from "semantic-ui-react";

export default function SaleResponsive({ sale, formatter, setDetails }) {
  return (
    <div
      style={{ marginTop: 10, boxShadow: "0px 2px 8px #CFD5DA", padding: 7 }}
      onClick={() => setDetails(sale.details)}
    >
      <Grid columns={16}>
        <Grid.Column computer={5}>
          <strong>Serie:</strong> {sale.serie}
        </Grid.Column>
        <Grid.Column computer={5}>
          <strong>Folio:</strong> {sale.folio}
        </Grid.Column>
        <Grid.Column computer={5}>
          <strong>Fecha:</strong> {sale.fecha.substring(0, 10)}
        </Grid.Column>
        <Grid.Column computer={5}>
          <strong>Descuento:</strong> {formatter.format(sale.importe_descuento)}
        </Grid.Column>
        <Grid.Column computer={5}>
          <strong>Total:</strong> {formatter.format(sale.total)}
        </Grid.Column>
        <Grid.Column computer={5}>
          <strong>Ecopesos:</strong>{" "}
          {sale.wallet?.added_points > 0
            ? formatter.format(sale.wallet.added_points)
            : 0}
        </Grid.Column>
      </Grid>
    </div>
  );
}
