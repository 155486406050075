import apiResource from "../api/apiResource";

const serviceUrl = 'promotions';

const PromotionService = {
    list: async () => {
      const response = await apiResource.fetch('GET', serviceUrl);
      return {success: true, data: response.data};
    },
}

export default PromotionService;