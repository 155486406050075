import React from "react";
import monedero from "../../assets/img/monedero_nuevo.png";

export default function Wallet({ points }) {
  return (
    <div className="wallet-card-img">
      <img alt="" src={monedero} />
      <div className={`values-container`}>
        <div className="wallet-card-points">${points}</div>
      </div>
    </div>
  );
}
