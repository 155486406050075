import React, { Fragment, useState, useEffect, useRef } from "react";
import { Table } from "semantic-ui-react";
import EcopesosService from "../../services/ecopesosService";
import NotificationAlert from "react-notification-alert";

export default function EcopesosList() {
  const notificationAlertRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [wallet, setWallet] = useState([]);

  useEffect(() => {
    const getWallet = async () => {
      setIsLoading(true);
      setWallet([]);
      const response = await EcopesosService.getHistoryWallet();
      if (response.success) {
        setIsLoading(false);
        setWallet(response.data);
      } else {
        setIsLoading(false);
        notifyError();
      }
    };
    getWallet();
  }, []);

  const notifyError = () => {
    var options = {};
    const message =
      "Ocurrió un error al obtener el historial. Inténtalo de nuevo más tarde.";
    options = {
      place: "tc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "warning",
      icon: "now-ui-icons travel_info",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <Fragment>
      <NotificationAlert ref={notificationAlertRef} />
      {isLoading && <div>Cargando...</div>}
      {!isLoading && (
        <div>
          <div className="sales-table-computer">
            <Table celled only="computer">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Fecha</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Tipo</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Total Venta</Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Ecopesos ganados
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Ecopesos utilizados
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>Balance</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {wallet.length > 0 &&
                  wallet.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {item.sale.fecha.substring(0, 10)}
                      </Table.Cell>
                      <Table.Cell>
                        {item.added_points > 0 ? "VENTA" : "CANJE DE ECOPESOS"}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {item.remote_sale_id > 0 && item.added_points > 0
                          ? formatter.format(item.sale.total)
                          : ""}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {item.added_points > 0
                          ? formatter.format(item.added_points)
                          : ""}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {item.used_points_inthissale > 0
                          ? formatter.format(item.used_points_inthissale)
                          : ""}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {formatter.format(item.balance_points)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {wallet.length === 0 && (
                  <Table.Row>
                    <Table.Cell colspan="7">
                      No se encontraron movimientos
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
          <div className="sales-table-mobile">
            {wallet.length > 0 &&
              wallet.map((item, index) => (
                <div key={index}>Aqui resultados</div>
              ))}
            {wallet.length === 0 && <div>No se encontraron movimientos</div>}
          </div>
        </div>
      )}
    </Fragment>
  );
}
