import React from "react";
import { Collapse, Navbar, NavbarToggler, Container } from "reactstrap";

import AuthService from "../../services/authService";
import defaultAvatar from "../../assets/img/default-avatar.png";

class Header extends React.Component {
  state = {
    isOpen: false,
    dropdownOpen: false,
    color: "transparent",
    name: "",
    avatar: "",
    points: 0,
  };
  sidebarToggle = React.createRef();
  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "white",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  dropdownToggle = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "white",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  };
  async componentDidMount() {
    const user = await AuthService.getUser();
    this.setState({
      name: user.name + " " + user.last_name,
      avatar: user.photo,
      points: user.points < 0 ? 0 : user.points,
    });
    window.addEventListener("resize", this.updateColor.bind(this));
  }

  render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={this.state.color}
        expand="lg"
        className={
          "navbar-absolute fixed-top " +
          (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            {/* <NavbarBrand href="/">{this.getBrand()}</NavbarBrand> */}
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            {this.state.name} | {this.state.points} Ecopesos
            {this.state.avatar ? (
              <img alt="" src={this.state.avatar} className="header-avatar" />
            ) : (
              <img alt="" src={defaultAvatar} className="header-avatar" />
            )}
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
