import React from "react";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

class Privacy extends React.Component {
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={12}>
              <Card className="card-green">
                <CardHeader>
                  <h5 className="title">Aviso de Privacidad</h5>
                </CardHeader>
                <CardBody>  
                  <p>
                    Por este medio se le informa que en términos de la <strong>LEY FEDERAL DE PROTECCION DE DATOS
                    PERSONALES</strong>, La empresa <strong>ECOCLIMAS</strong> está pegado a la normatividad que rige el <strong>AVISO DE
                    PRIVACIDAD</strong>, motivo por la cual, la información personal que nos proporcione es <strong>CONFIDENCIAL</strong>;
                    de tal manera que <strong>NO SERA TRANSFERIDA</strong> a terceros con el fin de salvaguardar su confidencialidad
                  </p>
                  <p style={{ textAlign: 'center'}}><br />Atte</p>
                  <p style={{ textAlign: 'center'}}><br />ECOCLIMAS</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Privacy;
