import React, { useEffect, useState} from 'react';
import NewsService from '../../services/newsService';

import Slide from '../Slide'

export default function NewsSlide () {
  const [news, setNews] = useState([]);
  const [newsMobile, setNewsMobile] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      const response = await NewsService.list();
      if(response.success){
        const respNews = response.data;
        const list = respNews.map(item => ({src: item.url_image, altText:'', caption:''}));
        const listMobile = respNews.map(item => ({src: item.url_image_mobile, altText:'', caption:''}));
        setNews(list);
        setNewsMobile(listMobile);
      }
    }
    getNews()
  },[])
  
  return (
    <div>
      <div className="slideDesktop">
        <Slide slides={news}/>
      </div>
      <div className="slideMobile">
        <Slide slides={newsMobile}/>
      </div>
    </div>
  )
}