import React from 'react'
import { useHistory } from "react-router-dom";
import AuthService from '../services/authService'

export default function Logout (){
  let history = useHistory();

  const handleLogout = async () => {
    await AuthService.logOut();
    history.push("/login");
  }

  handleLogout();

  return(
    <span>Cerrando sesion</span>
  )
}