import React, { useEffect, useState, useRef } from "react";
import { Card, CardHeader, CardBody, FormGroup, Row, Col } from "reactstrap";
import { Button, Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import ClientService from "../../services/clientService";
import NotificationAlert from "react-notification-alert";

import AuthService from "../../services/authService";
export default function Profile({ user }) {
  const [sending, setSending] = useState(false);
  const [errorPassword, setErrorPassword] = useState("");
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    triggerValidation,
  } = useForm({
    defaultValues: {
      current_password: "",
      password: "",
      confirm_password: "",
    },
  });
  const notificationAlertRef = useRef(null);

  useEffect(() => {
    register({ name: "current_password" }, { required: true });
    register({ name: "password" }, { required: true });
    register({ name: "confirm_password" }, { required: true });
  }, [register]);

  const onSubmit = async (data, e) => {
    if (data.password !== data.confirm_password) {
      setErrorPassword("Las contraseñas no coinciden");
    } else if (data.password.length < 8) {
      setErrorPassword("La contraseña debe contener al menos 8 caracteres");
    } else {
      setErrorPassword("");
      setSending(true);
      try {
        await ClientService.changePassword(data);
        notify(true);
        setSending(false);
        e.target.reset();
      } catch (error) {
        const { message } = error.response.data;
        notify(false, message);
        setSending(false);
      }
    }
  };

  const notify = (type, msg = "") => {
    var options = {};
    const message = type ? `Tu contraseña ha sido actualizada con éxito.` : msg;
    options = {
      place: "tc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type ? "success" : "danger",
      icon: type
        ? "now-ui-icons ui-1_check"
        : "now-ui-icons ui-1_simple-remove",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Cambiar contraseña</h5>
            </CardHeader>
            <CardBody>
              <Form size="large" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Contraseña actual</label>
                      <Form.Input
                        placeholder=""
                        type="text"
                        name="current_password"
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={errors.current_password ? true : false}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="4">
                    <FormGroup>
                      <label>Nueva Contraseña</label>
                      <Form.Input
                        placeholder=""
                        type="text"
                        name="password"
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={
                          errors.password || !!errorPassword ? true : false
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="4">
                    <FormGroup>
                      <label>Confirmar Nueva Contraseña</label>
                      <Form.Input
                        placeholder=""
                        type="text"
                        name="confirm_password"
                        onChange={async (e, { name, value }) => {
                          setValue(name, value);
                          await triggerValidation(name);
                        }}
                        error={
                          errors.confirm_password || !!errorPassword
                            ? true
                            : false
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {!!errorPassword && (
                  <Row>
                    <Col className="pr-1" md="12">
                      {errorPassword}
                    </Col>
                  </Row>
                )}
                <hr />
                <Button
                  disabled={sending}
                  type="submit"
                  className="sign-button"
                >
                  Cambiar Contraseña
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
