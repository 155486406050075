const apiRoot = {
  dev: "http://localhost:8000/api",
  qa: "",
  prod: "https://administrador.clubinstala.com/api",
};

function getApiRoot() {
  return apiRoot.prod;
}

export default getApiRoot();
