import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { NavLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthService from "../../services/authService";
import monito from "../../assets/img/monito.png";
import NotificationAlert from "react-notification-alert";
import EmailValidate from "./steps/EmailValidate";
import VerifyCode from "./steps/VerifyCode";
import ChangePassword from "./steps/ChangePassword";

export default function PasswordRecoveryForm() {
  const [sending, setSending] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [step, setStep] = useState(1);
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    triggerValidation,
  } = useForm();
  let history = useHistory();

  useEffect(() => {
    register({ name: "email" });
    register({ name: "code" });
    register({ name: "password" });
    register({ name: "confirm_password" });
  }, [register]);

  const notificationAlertRef = useRef(null);

  const onSubmit = async (data, e) => {
    setEmailError("");
    setCodeError("");
    setPasswordError("");
    switch (step) {
      case 1:
        await validateEmail(data);
        break;
      case 2:
        await validateCode(data);
        break;
      case 3:
        await validatePassword(data);
        break;
    }

    // setStep(step + 1);
    // alert(step);
    // if (step === 3) {
    //   if (data.password !== data.confirm_password) {
    //     setSamePassword(false);
    //   }

    //   alert("finalizado, enviamos a actualizar");
    // }
    // history.push("/ingresarcodigo");
    // setSending(true);
    // const responseLogin = await AuthService.login(data);
    // setSending(false);
    // if (responseLogin.success) {
    //   await AuthService.saveUser(responseLogin.data);
    //   history.push("/inicio");
    // } else {
    //   notify(false, responseLogin.data);
    // }
  };

  const validateEmail = async (data) => {
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!data.email || !regEmail.test(data.email)) {
      setEmailError("Ingresa un email válido");
    } else {
      setSending(true);
      const { email } = data;
      const response = await AuthService.passwordResetGenerateCode({ email });
      setSending(false);
      if (response.success) {
        notify(true, "Revisa tu correo electrónico, recibiste un código.");
        setStep(2);
      } else {
        setEmailError(response.data);
      }
    }
  };

  const validateCode = async (data) => {
    if (!data.code) {
      setCodeError("Ingresa el código");
    } else {
      setSending(true);
      const { email, code } = data;
      const response = await AuthService.passwordResetVerifyCode({
        email,
        token: code,
      });
      setSending(false);
      if (response.success) {
        notify(true, "El código es correcto, ya casi cambias tu contraseña.");
        setStep(3);
      } else {
        setCodeError(response.data);
      }
    }
  };

  const validatePassword = async (data) => {
    if (!data.password) {
      setPasswordError("Ingresa una contraseña");
    } else if (data.password.length < 8) {
      setPasswordError("La contraseña debe tener al menos 8 caracteres");
    } else if (data.password != data.confirm_password) {
      setPasswordError("Las contraseñas no coinciden");
    } else {
      setSending(true);
      const { email, code, password } = data;
      const response = await AuthService.passwordResetChangePassword({
        email,
        token: code,
        password,
      });
      if (response.success) {
        notify(
          true,
          "La contraseña ha sido cambiada exitosamente, utilízala para iniciar sesión."
        );
        setTimeout(() => {
          history.push("/");
        }, 3000);
      } else {
        setSending(false);
        setPasswordError(response.data);
      }
    }
  };

  const notify = (success, msg = "") => {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: success ? "success" : "danger",
      icon: success
        ? "now-ui-icons ui-1_check"
        : "now-ui-icons ui-1_simple-remove",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <div className="bg-sign">
      <NotificationAlert ref={notificationAlertRef} />

      <Grid
        columns={16}
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column computer={3} only="computer">
          <img alt="" src={monito} />
        </Grid.Column>
        <Grid.Column mobile={13} computer={6}>
          <Form size="large" onSubmit={handleSubmit(onSubmit)}>
            <Segment stacked className="signform">
              <Header as="h1" className="sign-title" textAlign="center">
                Recuperación de contraseña
              </Header>
              <div style={{ marginBottom: 10 }}>
                {step === 1 && (
                  <EmailValidate setValue={setValue} errors={emailError} />
                )}
                {step === 2 && (
                  <VerifyCode setValue={setValue} errors={codeError} />
                )}
                {step === 3 && (
                  <>
                    <ChangePassword
                      setValue={setValue}
                      errors={passwordError}
                    />
                  </>
                )}
              </div>
              <Button disabled={sending} type="submit" className="sign-button">
                {!sending ? "Enviar" : "Enviando"}
              </Button>
              <div className="sign-not-message">
                Regresar a <NavLink to={"/login"}>Iniciar sesión</NavLink>
              </div>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
}
