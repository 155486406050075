import React from "react";
import Profile from "../components/Profile";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import AuthService from "../services/authService"

class User extends React.Component {
  state = {
    user: null
  }

  componentDidMount = async () => {
    const user = await AuthService.getUser();
    this.setState({user});
  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        {this.state.user &&
          <Profile user={this.state.user}/>
        }
      </>
    );
  }
}

export default User;
