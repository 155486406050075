import React from "react";
import ContactForm from "../components/Contact";
import { Card, CardBody, Row, Col } from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

class Contact extends React.Component {
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="contactform">  
                  <ContactForm />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Contact;
