import React, { Fragment, useState, useEffect, useRef } from "react";
import { Form, Icon, Table } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import "moment/locale/es";
import SaleService from "../../services/saleService";
import NotificationAlert from "react-notification-alert";
import DetailList from "./Detail";
import DetailListResponsive from "./DetailResponsive";
import SaleResponsive from "./SaleResponsive";

export default function SalesList() {
  const notificationAlertRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDateStart] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  const [sales, setSales] = useState([]);
  const [searchSales, setSearchSales] = useState(true);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const getSales = async () => {
      setIsLoading(true);
      setSales([]);
      const responseSales = await SaleService.get({ dateStart, dateEnd });
      if (responseSales.success) {
        setIsLoading(false);
        setSearchSales(false);
        setSales(responseSales.data);
      } else {
        setIsLoading(false);
        setSearchSales(false);
        notifyError();
      }
    };
    if (searchSales) {
      getSales();
    }
  }, [dateStart, dateEnd, searchSales]);

  const notifyError = () => {
    var options = {};
    const message =
      "Ocurrió un error al obtener las ventas. Inténtalo de nuevo más tarde.";
    options = {
      place: "tc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "warning",
      icon: "now-ui-icons travel_info",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <Fragment>
      <NotificationAlert ref={notificationAlertRef} />
      <Form>
        <Form.Group>
          <DateInput
            localization="es"
            label="Fecha Inicio"
            name="date_start"
            placeholder=""
            dateFormat="YYYY-MM-DD"
            value={dateStart}
            iconPosition="left"
            popupPosition="bottom center"
            closable="true"
            onChange={(e, { name, value }) => setDateStart(value)}
          />
          <DateInput
            localization="es"
            label="Fecha Fin"
            name="date_end"
            placeholder=""
            dateFormat="YYYY-MM-DD"
            value={dateEnd}
            iconPosition="left"
            popupPosition="bottom center"
            closable="true"
            onChange={(e, { name, value }) => setDateEnd(value)}
          />
          <Form.Button
            primary
            style={{ marginTop: "23px" }}
            onClick={() => setSearchSales(true)}
          >
            <Icon name="search" />
            {isLoading ? "Buscando..." : "Buscar"}
          </Form.Button>
        </Form.Group>
      </Form>
      {!isLoading && details.length === 0 && (
        <div>
          <div className="sales-table-computer">
            <Table celled only="computer">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>Serie</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Folio</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Fecha</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Descuento</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Total</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Ecopesos</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sales.length > 0 &&
                  sales.map((item, index) => (
                    <Table.Row
                      key={index}
                      onClick={() => setDetails(item.details)}
                      style={{ cursor: "pointer" }}
                    >
                      <Table.Cell>{item.serie}</Table.Cell>
                      <Table.Cell>{item.folio}</Table.Cell>
                      <Table.Cell>{item.fecha.substring(0, 10)}</Table.Cell>
                      <Table.Cell className="text-right">
                        {formatter.format(item.importe_descuento)}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {formatter.format(item.total)}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {item.wallet?.added_points > 0
                          ? formatter.format(item.wallet.added_points)
                          : 0}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {sales.length === 0 && (
                  <Table.Row>
                    <Table.Cell colspan="7">
                      No se encontraron ventas en las fechas seleccionadas
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
          <div className="sales-table-mobile">
            {sales.length > 0 &&
              sales.map((item, index) => (
                <SaleResponsive
                  key={index}
                  sale={item}
                  formatter={formatter}
                  setDetails={setDetails}
                />
              ))}
            {sales.length === 0 && (
              <div>No se encontraron ventas en las fechas seleccionadas</div>
            )}
          </div>
        </div>
      )}
      {details.length > 0 && (
        <Fragment>
          <Form.Button
            primary
            style={{ marginTop: "23px" }}
            onClick={() => setDetails([])}
          >
            Regresar
          </Form.Button>
          <div
            className="sales-detail-table-computer"
            style={{ marginTop: 10 }}
          >
            <DetailList details={details} formatter={formatter} />
          </div>
          <div className="sales-detail-table-mobile" style={{ marginTop: 10 }}>
            <DetailListResponsive details={details} formatter={formatter} />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
