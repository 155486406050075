import apiResource from "../api/apiResource";

const AuthService = {
  register: async (data) => {
    try {
      const response = await apiResource.fetch("POST", "clients", data);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error.response.data.message };
    }
  },

  login: async (data) => {
    try {
      const response = await apiResource.fetch("POST", "auth/login", data);
      return { success: true, data: response };
    } catch (error) {
      return { success: false, data: "Los datos introducidos son incorrectos" };
    }
  },

  passwordResetGenerateCode: async (data) => {
    try {
      const response = await apiResource.fetch(
        "POST",
        "auth/passwordreset",
        data
      );
      return { success: true, data: response };
    } catch (error) {
      return { success: false, data: error.response.data.message };
    }
  },

  passwordResetVerifyCode: async (data) => {
    try {
      const response = await apiResource.fetch(
        "POST",
        "auth/passwordreset/validatetoken",
        data
      );
      return { success: true, data: response };
    } catch (error) {
      return { success: false, data: error.response.data.message };
    }
  },

  passwordResetChangePassword: async (data) => {
    try {
      const response = await apiResource.fetch(
        "POST",
        "auth/passwordreset/changepassword",
        data
      );
      return { success: true, data: response };
    } catch (error) {
      return { success: false, data: error.response.data.message };
    }
  },

  saveUser: async (data) => {
    await localStorage.setItem("user", JSON.stringify(data.data));
    await localStorage.setItem("token", JSON.stringify(data.access_token));
  },

  updateUser: async (data) => {
    await localStorage.setItem("user", JSON.stringify(data.data));
  },

  getUser: async () => {
    // const user = await localStorage.getItem("user");
    const response = await apiResource.fetch("GET", "auth/user");
    await localStorage.setItem("user", JSON.stringify(response));
    return response;
  },

  getToken: async () => {
    const token = await localStorage.getItem("token");
    return token ? token.slice(1, token.length - 1) : null;
  },

  isLogged: async () => {
    const user = await localStorage.getItem("user");
    if (user) return true;

    return false;
  },

  logOut: async () => {
    try {
      await apiResource.fetch("POST", "auth/logout");
      await localStorage.removeItem("user");
      await localStorage.removeItem("token");
      return { success: true };
    } catch (error) {
      return { success: false, data: "" };
    }
  },

  loginLog: async (channel) => {
    try {
      const response = await apiResource.fetch("POST", "login_logs", {
        channel,
      });
      console.log("response", { response });
      return { success: true, data: response };
    } catch (error) {
      return { success: false, data: error.response.data.message };
    }
  },
};

export default AuthService;
