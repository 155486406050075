import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { NavLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthService from "../../services/authService";
import monito from "../../assets/img/monito.png";
import NotificationAlert from "react-notification-alert";

export default function LoginForm() {
  const [sending, setSending] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    triggerValidation,
  } = useForm();
  let history = useHistory();

  useEffect(() => {
    register({ name: "email" }, { required: true });
    register({ name: "password" }, { required: true });
  }, [register]);

  useEffect(() => {
    const checkLogin = async () => {
      const isLogged = await AuthService.isLogged();
      if (isLogged) {
        history.push("/inicio");
      }
    };
    checkLogin();
  }, [history]);

  const notificationAlertRef = useRef(null);

  const onSubmit = async (data, e) => {
    setSending(true);
    const responseLogin = await AuthService.login(data);
    setSending(false);
    if (responseLogin.success) {
      await AuthService.saveUser(responseLogin.data);
      await AuthService.loginLog("WEB");
      history.push("/inicio");
    } else {
      notify(false, responseLogin.data);
    }
  };

  const notify = (type, msg) => {
    var options = {};
    const message = msg;
    options = {
      place: "tc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_simple-remove",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <div className="bg-sign">
      <NotificationAlert ref={notificationAlertRef} />

      <Grid
        columns={16}
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column computer={3} only="computer">
          <img alt="" src={monito} />
        </Grid.Column>
        <Grid.Column mobile={13} computer={6}>
          <Form size="large" onSubmit={handleSubmit(onSubmit)}>
            <Segment stacked className="signform">
              <Header as="h1" className="sign-title" textAlign="center">
                Iniciar sesión
              </Header>
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="Ingresa Correo electrónico o Teléfono"
                name="email"
                onChange={async (e, { name, value }) => {
                  setValue(name, value);
                  await triggerValidation(name);
                }}
                error={errors.email ? true : false}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Contraseña"
                name="password"
                type="password"
                onChange={async (e, { name, value }) => {
                  setValue(name, value);
                  await triggerValidation(name);
                }}
                error={errors.password ? true : false}
              />
              <Button disabled={sending} type="submit" className="sign-button">
                {!sending ? "Iniciar Sesión" : "Enviando"}
              </Button>
              <div className="sign-not-message">
                <NavLink to={"/recuperarpassword"}>
                  ¿Olvidaste tu contraseña?
                </NavLink>
              </div>
              <div className="sign-not-message">
                ¿No tienes una cuenta?
                <NavLink to={"/registro"}>Regístrate</NavLink>
              </div>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
}
