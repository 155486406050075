import React, { useEffect, useState} from 'react';
import PromotionService from '../../services/promotionService';

import Slide from '../Slide'

export default function PromotionSlide () {
  const [promotions, setPromotions] = useState([]);
  const [promotionsMobile, setPromotionsMobile] = useState([]);

  useEffect(() => {
    const getPromotions = async () => {
      const response = await PromotionService.list();
      if(response.success){
        const respPromotions = response.data;
        const list = respPromotions.map(item => ({src: item.url_image, altText:'', caption:''}));
        const listMobile = respPromotions.map(item => ({src: item.url_image_mobile, altText:'', caption:''}));
        setPromotions(list);
        setPromotionsMobile(listMobile);
      }
    }
    getPromotions()
  },[])
  
  return (
    <div>
      <div className="slideDesktop">
        <Slide slides={promotions}/>
      </div>
      <div className="slideMobile">
        <Slide slides={promotionsMobile}/>
      </div>
    </div>
  )
}