import React, { useMemo, useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import ClientService from "../../services/clientService";

export default function HistoryPointsChart() {
  const [isLoading, setIsLoading] = useState(false);
  const [labelsMonths, setLabelMonths] = useState([]);
  const [values, setValues] = useState([]);
  const monthsNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    const getHistory = async () => {
      setIsLoading(true);
      const response = await ClientService.getPointsHistory();
      if (response.success) {
        const data = response.data;
        const months = [];
        const monthValues = [];
        data.map((item) => {
          const m =
            item.fecha.substring(5) < 10
              ? item.fecha.substring(6)
              : item.fecha.substring(5);
          months.push(monthsNames[m - 1]);
          monthValues.push(item.points);
        });
        setLabelMonths(months);
        setValues(monthValues);
        setIsLoading(false);
      }
    };
    getHistory();
  }, []);

  const dashboardPanelChart = {
    data: (canvas) => {
      const ctx = canvas.getContext("2d");
      var chartColor = "#FFFFFF";
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#80b6f4");
      gradientStroke.addColorStop(1, chartColor);
      var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

      return {
        labels: labelsMonths,
        datasets: [
          {
            label: "ECOPESOS",
            borderColor: chartColor,
            pointBorderColor: chartColor,
            pointBackgroundColor: "#2c2c2c",
            pointHoverBackgroundColor: "#2c2c2c",
            pointHoverBorderColor: chartColor,
            pointBorderWidth: 1,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: values,
          },
        ],
      };
    },
    options: {
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 0,
          bottom: 0,
        },
      },
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: "#fff",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      legend: {
        position: "bottom",
        fillStyle: "#FFF",
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 10,
            },
            gridLines: {
              drawTicks: true,
              drawBorder: false,
              display: true,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              color: "rgba(255,255,255,0.1)",
            },
            ticks: {
              padding: 10,
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
            },
          },
        ],
      },
    },
  };

  return isLoading ? (
    <div>Cargando...</div>
  ) : (
    <Line
      data={dashboardPanelChart.data}
      options={dashboardPanelChart.options}
    />
  );
}
