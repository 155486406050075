import axios from 'axios';
import apiRoot from "../api/apiRoot";

const CityService = {
    list: async () => {
      return axios.get(`${apiRoot}/cities`)
      .then(res => {
        return {success: true, data: res.data.data};
      })
      .catch(error => {
        const message = error.response.data.message
        return {success: false, data: message};
      });
    },
}

export default CityService;