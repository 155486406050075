import Dashboard from "views/Dashboard.jsx";
import RankingHistory from "views/RankingHistory.jsx";
import GameRules from "views/GameRules.jsx";
import Privacy from "views/Privacy.jsx";
import Maps from "views/Maps.jsx";
import Sales from "views/Sales.jsx";
import Ecopesos from "views/Ecopesos.jsx";
import Promotions from "views/Promotions.jsx";
import News from "views/News.jsx";
import UserPage from "views/UserPage.jsx";
import Logout from "views/Logout";
import Contact from "views/Contact";
var dashRoutes = [
  {
    path: "/inicio",
    name: "Inicio",
    icon: "fa-home",
    component: Dashboard,
    layout: "",
  },
  {
    path: "/tiendas",
    name: "Ubicación de tiendas",
    icon: "fa-map-pin",
    component: Maps,
    layout: "",
  },
  {
    path: "/historial-compras",
    name: "Historial de compras",
    icon: "fa-list",
    component: Sales,
    layout: "",
  },
  {
    path: "/historial-ecopesos",
    name: "Historial de Ecopesos",
    icon: "fa-history",
    component: Ecopesos,
    layout: "",
  },
  {
    path: "/historial-ranking",
    name: "Historial de ranking",
    icon: "fa-code-branch",
    component: RankingHistory,
    layout: "",
  },
  {
    path: "/promociones",
    name: "Promociones",
    icon: "fa-certificate",
    component: Promotions,
    layout: "",
  },
  {
    path: "/anuncios",
    name: "Anuncios",
    icon: "fa-images",
    component: News,
    layout: "",
  },
  {
    path: "/reglas-del-juego",
    name: "Reglas del juego",
    icon: "fa-gamepad",
    component: GameRules,
    layout: "",
  },
  {
    path: "/aviso-de-privacidad",
    name: "Aviso de Privacidad",
    icon: "fa-edit",
    component: Privacy,
    layout: "",
  },
  {
    path: "/perfil",
    name: "Perfil de Usuario",
    icon: "fa-user",
    component: UserPage,
    layout: "",
  },
  {
    path: "/contacto",
    name: "Contactános",
    icon: "fa-paper-plane",
    component: Contact,
    layout: "",
  },
  {
    path: "/cerrarsesion",
    name: "Cerrar Sesión",
    icon: "fa-sign-out-alt",
    component: Logout,
    layout: "",
  },
];
export default dashRoutes;
