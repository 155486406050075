import apiResource from "../api/apiResource";
const serviceUrl = "";
const ClientService = {
  update: async (data) => {
    const response = await apiResource.fetch(
      "POST",
      `${serviceUrl}updateprofile`,
      data
    );
    return { success: true, data: response.data };
  },
  changePassword: async (data) => {
    const response = await apiResource.fetch(
      "POST",
      `${serviceUrl}updateprofile/changepassword`,
      data
    );
    return { success: true, data: response.data };
  },
  getNextLevel: async () => {
    const response = await apiResource.fetch(
      "GET",
      `${serviceUrl}reqnextlevel`
    );
    return { success: true, data: response.data };
  },
  getRankingHistory: async () => {
    const response = await apiResource.fetch(
      "GET",
      `${serviceUrl}history_ranking`
    );
    return { success: true, data: response.data };
  },
  getPointsHistory: async () => {
    const response = await apiResource.fetch(
      "GET",
      `${serviceUrl}history_ecopesos_mes`
    );
    return { success: true, data: response.data };
  },
};

export default ClientService;
