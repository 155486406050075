import apiResource from "../api/apiResource";

const serviceUrl = "branchoffices";

const branchOfficeService = {
  list: async () => {
    const response = await apiResource.fetch("GET", serviceUrl);
    return { success: true, data: response.data };
  },
  listByCity: async (cityId) => {
    const response = await apiResource.fetch(
      "GET",
      `cities/${cityId}/${serviceUrl}`
    );
    return { success: true, data: response.data };
  },
};

export default branchOfficeService;
