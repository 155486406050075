import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Input, Form, Grid, Header, Segment } from "semantic-ui-react";
import { NavLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthService from "../../services/authService";
import CityService from "../../services/cityService";
import BranchOfficeService from "../../services/branchOfficeService";
import NotificationAlert from "react-notification-alert";
import monito from "../../assets/img/monito.png";

export default function SignUpForm() {
  const [samePassword, setSamePassword] = useState(true);
  const [rfcErrorMessage, setRfcErrorMessage] = useState(undefined);
  const [businessNameErrorMessage, setBusinessNameErrorMessage] = useState(
    undefined
  );
  const [sending, setSending] = useState(false);
  const [cities, setCities] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [personTypes] = useState([
    {
      key: "PF",
      text: "Persona Física",
      value: "PF",
    },
    { key: "PM", text: "Persona Moral", value: "PM" },
  ]);
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    triggerValidation,
  } = useForm();

  let history = useHistory();

  const REGEX_RFC = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/;
  const REGEX_PHONE = /^\d{10}$/;
  const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    const getCities = async () => {
      const responseCities = await CityService.list();
      if (responseCities.success) {
        const listCities = responseCities.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setCities(listCities);
      }
    };
    getCities();
  }, []);

  useEffect(() => {
    register({ name: "name" }, { required: true });
    register({ name: "last_name" }, { required: true });
    register({ name: "maternal_surname" });
    register({ name: "email" });
    register({ name: "phone" });
    register({ name: "password" }, { required: true, minLength: 8 });
    register({ name: "confirm_password" }, { required: true });
    register({ name: "city_id" }, { required: true });
    register({ name: "branchoffice_id" }, { required: true });
    register({ name: "person_type" }, { required: true });
    register({ name: "business_name" });
    register({ name: "rfc" });
  }, [register]);

  useEffect(() => {
    const checkLogin = async () => {
      const isLogged = await AuthService.isLogged();
      if (isLogged) {
        history.push("/inicio");
      }
    };
    checkLogin();
  }, [history]);

  const notificationAlertRef = useRef(null);

  const onSubmit = async (data, e) => {
    setSamePassword(true);
    console.log(data);
    let hasError = false;
    if (data.person_type === "PM" && !data.business_name) {
      hasError = true;
      setBusinessNameErrorMessage("Ingresa tu razón social");
    }
    if (data.person_type === "PM" && !isValidRfc(data.rfc, data.person_type)) {
      hasError = true;
      setRfcErrorMessage("Ingresa un RFC válido para Persona Moral");
    }

    if (data.person_type === "PF" && !isValidRfc(data.rfc, data.person_type)) {
      hasError = true;
      setRfcErrorMessage("Ingresa un RFC válido para Persona Física");
    }

    if (data.password !== data.confirm_password) {
      setSamePassword(false);
      hasError = true;
    }

    if (!hasError) {
      if (!data.phone && !data.email) {
        notify(false, "Debe ingresar un teléfono o un email");
        hasError = true;
      } else if (data.phone && !REGEX_PHONE.test(data.phone)) {
        notify(false, "Debe ingresar un teléfono válido");
        hasError = true;
      } else if (data.email && !REGEX_EMAIL.test(data.email)) {
        notify(false, "Debe ingresar un email válido");
        hasError = true;
      } else {
        setSending(true);
        const r = await AuthService.register(data);
        if (r.success) {
          notify(true);
          if (!data.email && data.phone) {
            data.email = data.phone;
          }

          const responseLogin = await AuthService.login(data);
          setSending(false);
          if (responseLogin.success) {
            await AuthService.saveUser(responseLogin.data);
            history.push("/inicio");
          } else {
            history.push("/login");
          }
        } else {
          notify(false, r.data);
          setSending(false);
        }
      }
    }
  };

  const isValidRfc = (rfc, personType) => {
    if (personType === "PM" && !rfc) {
      return false;
    }

    const upperCaseRFC = rfc ? rfc.toUpperCase() : "";
    setRfcErrorMessage("");
    console.log("Llegó aqui", { upperCaseRFC, personType });
    console.log(REGEX_RFC.test(upperCaseRFC));
    if (
      personType === "PM" &&
      rfc &&
      (!REGEX_RFC.test(upperCaseRFC) || rfc.length !== 12)
    ) {
      return false;
    }

    if (
      personType === "PF" &&
      rfc &&
      (!REGEX_RFC.test(upperCaseRFC) || rfc.length !== 13)
    ) {
      return false;
    }

    return true;
  };

  const handleChangeCity = useCallback(
    async (value) => {
      setValue("branchoffice_id", undefined);
      setBranchOffices([]);
      if (value > 0) {
        const resp = await BranchOfficeService.listByCity(value);
        if (resp.success) {
          const listCities = resp.data.map((item) => ({
            key: item.id,
            text: item.name,
            value: item.id,
          }));
          setBranchOffices(listCities);
        }
      }
    },
    [setValue, setBranchOffices]
  );

  const notify = (type, msg = "") => {
    var options = {};
    const message = type
      ? `Bienvenido a Club Instala - Tu cuenta ha sido creada exitosamente. Utiliza los datos ingresados para Iniciar Sesión.`
      : msg;
    options = {
      place: "tc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type ? "success" : "danger",
      icon: type
        ? "now-ui-icons ui-1_check"
        : "now-ui-icons ui-1_simple-remove",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <div className="bg-sign">
      <NotificationAlert ref={notificationAlertRef} />
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column computer={3} only="computer">
          <img alt="" src={monito} />
        </Grid.Column>
        <Grid.Column mobile={13} computer={10}>
          <Form size="large" onSubmit={handleSubmit(onSubmit)}>
            <Segment stacked className="signform">
              <Header as="h1" className="sign-title" textAlign="center">
                Crear una cuenta
              </Header>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Nombre(s)"
                  name="name"
                  onChange={async (e, { name, value }) => {
                    setValue(name, value);
                    await triggerValidation(name);
                  }}
                  error={errors.name ? true : false}
                />
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Apellido Paterno"
                  name="last_name"
                  onChange={async (e, { name, value }) => {
                    setValue(name, value);
                    await triggerValidation(name);
                  }}
                  error={errors.last_name ? true : false}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Apellido Materno"
                  name="maternal_surname"
                  onChange={async (e, { name, value }) => {
                    setValue(name, value);
                    await triggerValidation(name);
                  }}
                />

                <Form.Field error={errors.phone ? true : false}>
                  <Input
                    fluid
                    icon="phone"
                    iconPosition="left"
                    placeholder="Teléfono"
                    name="phone"
                    onChange={async (e, { name, value }) => {
                      setValue(name, value);
                      await triggerValidation(name);
                    }}
                  />

                  <span style={{ fontSize: "12px" }}>
                    Número telefónico a 10 dígitos
                  </span>
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Dropdown
                  placeholder="Selecciona Ciudad"
                  fluid
                  selection
                  name="city_id"
                  onChange={async (e, { name, value }) => {
                    setValue(name, value);
                    handleChangeCity(value);
                    await triggerValidation(name);
                  }}
                  error={errors.city_id ? true : false}
                  options={cities}
                />

                <Form.Dropdown
                  placeholder="Selecciona Sucursal"
                  fluid
                  selection
                  name="branchoffice_id"
                  onChange={async (e, { name, value }) => {
                    setValue(name, value);
                    await triggerValidation(name);
                  }}
                  error={errors.branchoffice_id ? true : false}
                  options={branchOffices}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Dropdown
                  placeholder="Tipo de Persona"
                  fluid
                  selection
                  name="person_type"
                  onChange={async (e, { name, value }) => {
                    setValue(name, value);
                    await triggerValidation(name);
                  }}
                  error={errors.person_type ? true : false}
                  options={personTypes}
                />

                <Form.Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder="Correo electrónico"
                  name="email"
                  onChange={async (e, { name, value }) => {
                    setValue(name, value);
                    await triggerValidation(name);
                  }}
                  error={errors.email ? true : false}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={errors.business_name ? true : false}>
                  <Input
                    fluid
                    icon="building"
                    iconPosition="left"
                    placeholder="Razón Social"
                    name="business_name"
                    onChange={async (e, { name, value }) => {
                      setValue(name, value);
                      setBusinessNameErrorMessage(undefined);
                      await triggerValidation(name);
                    }}
                  />
                  {businessNameErrorMessage && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {businessNameErrorMessage}
                    </span>
                  )}
                </Form.Field>

                <Form.Field error={errors.rfc ? true : false}>
                  <Input
                    fluid
                    icon="hashtag"
                    iconPosition="left"
                    placeholder="RFC"
                    name="rfc"
                    onChange={async (e, { name, value }) => {
                      setValue(name, value);
                      setRfcErrorMessage(undefined);
                      await triggerValidation(name);
                    }}
                  />

                  {rfcErrorMessage && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {rfcErrorMessage}
                    </span>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={errors.password ? true : false}>
                  <Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Contraseña"
                    type="password"
                    name="password"
                    onChange={async (e, { name, value }) => {
                      setValue(name, value);
                      await triggerValidation(name);
                    }}
                  />
                  {errors.password?.type === "minLength" && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      Contraseña debe tener al menos 8 caracteres.
                    </span>
                  )}
                </Form.Field>

                <Form.Field
                  error={
                    errors.confirm_password || !samePassword ? true : false
                  }
                >
                  <Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Confirmar Contraseña"
                    type="password"
                    name="confirm_password"
                    onChange={async (e, { name, value }) => {
                      setValue(name, value);
                      await triggerValidation(name);
                    }}
                  />
                  {!samePassword && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      Las contraseñas no coinciden
                    </span>
                  )}
                </Form.Field>
              </Form.Group>
              <Button disabled={sending} type="submit" className="sign-button">
                {!sending ? "Crear cuenta" : "Enviando"}
              </Button>
              <div className="sign-not-message">
                ¿Ya tienes una cuenta?
                <NavLink to={"/login"}>Iniciar Sesión</NavLink>
              </div>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
}
